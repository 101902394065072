import type {LinkDescriptor} from '@remix-run/server-runtime';

export function preloadFont(font: string): LinkDescriptor {
  return {
    rel: 'preload',
    as: 'font',
    crossOrigin: 'anonymous',
    type: 'font/woff2',
    href: `https://cdn.shopify.com/static/fonts/${font}`,
  };
}
